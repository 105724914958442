import lunr from 'lunr'

const searchButton = document.getElementsByClassName('c-btn--search')[0]
const searchMenu = document.getElementsByClassName('c-search')[0]
const searchInputClearButton = document.getElementsByClassName('c-btn--search-form')[0]
const searchIcon = document.getElementsByClassName('c-btn__div--search')[0]
const searchBar = document.getElementsByClassName('Search')[0]
const searchComponent = document.getElementsByClassName('search_component')[0]
const searchInput = document.getElementById('Search___input')
const searchForm = document.getElementsByClassName('_Search__search-form')[0]
/* The below constants should change name at some point when this is re-factored */
const searchNoResultsNode = document.getElementsByClassName('Search__no-results')[0]
const searchResultsNode = document.getElementById('search-results')

class Search {
  constructor () {
    this.searchButton = searchButton
    this.searchMenu = searchMenu
    this.searchInputClearButton = searchInputClearButton
    this.searchIcon = searchIcon
    this.searchBar = searchBar
    this.searchComponent = searchComponent
    this.searchInput = searchInput
    this.searchForm = searchForm
    this.searchNoResultsNode = searchNoResultsNode
    this.searchResultsNode = searchResultsNode
  }

  bind () {
    this.searchButton.addEventListener('click', (e) => this.searchComponentToggle(e))
    this.searchBar.addEventListener('click', (e) => this.searchComponentInputFocus(e))
    this.searchInput.addEventListener('keyup', (e) => this.searchComponentToggleResteOnInput(e))
    this.searchInput.addEventListener('focus', (e) => this.searchComponentFocusOrActive(e))
    this.searchInput.addEventListener('blur', (e) => this.searchComponentBlur(e))
    this.searchInputClearButton.addEventListener('click', (e) => this.searchComponentReset(e))
  }

  searchComponentToggle (event) {
    if (this.searchMenu.classList.contains('is-open')) {
      this.searchMenu.classList.remove('is-open')
      this.searchButton.classList.remove('is-open')
      this.searchIcon.classList.remove('is-open')
    } else {
      this.searchMenu.classList.add('is-open')
      this.searchButton.classList.add('is-open')
      this.searchIcon.classList.add('is-open')
    }
  }

  searchComponentReset (event) {
    this.searchInputClearButton.classList.remove('hide')
    if (this.searchNoResultsNode.hasChildNodes()) {
      this.searchNoResultsNode.removeChild(this.searchNoResultsNode.lastChild)
    }
    while (this.searchResultsNode.hasChildNodes()) {
      this.searchResultsNode.removeChild(
        this.searchResultsNode.lastChild
      )
    }
  }

  searchComponentToggleResteOnInput (event) {
    if (this.searchInput.value.length === 0) {
      this.searchInputClearButton.classList.remove('hide')
    } else {
      this.searchInputClearButton.classList.add('hide')
    }
  }

  searchComponentFocusOrActive (event) {
    this.searchForm.classList.add('is-active')
  }

  searchComponentBlur (event) {
    this.searchForm.classList.remove('is-active')
  }

  searchComponentInputFocus (event) {
    event.stopPropagation()
    this.searchInput.focus()
  }

  init () {
    this.loadIndexJson(
      this.addToSearchIndex(
        this.registerSearchHandler(
          document.getElementsByClassName('Search__loading-indicator')[0],
          document.getElementsByClassName('Search__initial')[0],
          document.getElementById('Search___input'),
          this.search(
            document.getElementsByClassName('Search__loading-indicator')[0],
            document.getElementsByClassName('Search__no-results')[0],
            this.renderSearchResults(
              document.getElementById('search-results'),
              document.getElementsByClassName('Search__loading-indicator')[0],
              document.getElementsByClassName('Search__no-results')[0]
            )
          )
        )
      )
    )
  }

  loadIndexJson (indexJsonLoadedFunction) {
    fetch('/index.json')
    .then(response => {
      if (!response.ok) {
        throw new Error(`Error getting Hugo index file: ${response.status}, ${response.statusText}`);
      }
      return response.json();
    })
    .then(data => {
      indexJsonLoadedFunction(data);
    })
    .catch(error => {
      console.error('Network request failed', error);
    });
  }

  addToSearchIndex (indexLoadedFunction) {
    return function (index) {
      var titles = {}

      var lunrIndex = lunr(function () {
        this.field('title', {
          boost: 40
        })
        this.field('tags', {
          boost: 10
        })
        this.ref('href')

        var that = this

        index.forEach(function (item) {
          that.add({ title: item.title, tags: item.tags, href: item.ref })
          // The lunr results only contain ref and score
          // so we have to keep track of any other values
          // we want to display ourselves
          titles[item.ref] = item.title
        })
      })
      indexLoadedFunction(lunrIndex, titles)
    }
  }

  search (searchLoadingNode, searchNoResultsNode, renderFactoryFunction) {
    return function (lunrIndex, titles) {
      var renderFunction = renderFactoryFunction(titles)
      return function (query) {
        searchNoResultsNode.style.display = 'block'
        while (searchNoResultsNode.hasChildNodes()) {
          searchNoResultsNode.removeChild(
            searchNoResultsNode.lastChild
          )
        }

        if (query.trim()) {
          var results = lunrIndex.search(query)
          // stop spinner as soon as we have results
          searchLoadingNode.style.display = 'none'

          if (results.length === 0) {
            searchNoResultsNode.appendChild(document.createTextNode('No Search Results for: \'' + query + '\''))
          }
          renderFunction(results)
        }

        // stop spinner with empty string
        searchLoadingNode.style.display = 'none'
      }
    }
  }

  renderSearchResults (searchResultsNode, searchLoadingNode, searchNoResultsNode) {
    return function (titles) {
      return function (results) {
        // Remove any existing content
        while (searchResultsNode.hasChildNodes()) {
          searchResultsNode.removeChild(
            searchResultsNode.lastChild
          )
        }

        results.forEach(function (result) {
          searchNoResultsNode.style.display = 'none'

          var i = document.createElement('div')
          i.className = 'Search__results-item'

          var a = document.createElement('a')
          a.setAttribute('href', result.ref)

          var s = document.createElement('span')
          var b = document.createElement('b')
          b.appendChild(document.createTextNode(
            titles[result.ref]
          ))

          s.appendChild(b)
          a.appendChild(s)
          i.appendChild(a)

          searchResultsNode.appendChild(i)
          searchLoadingNode.style.display = 'none'
        })
        searchResultsNode.style.display = 'block'
      }
    }
  }

  registerSearchHandler (searchLoadingNode, searchInitialNode, searchInputNode, searchFactoryFunction) {
    return function (lunrIndex, titles) {
      var searchFunction = searchFactoryFunction(lunrIndex, titles)
      // Register an oninput event handler
      searchInputNode.oninput = function (event) {
        searchInitialNode.style.display = 'none'
        searchLoadingNode.style.display = 'block'
        var query = event.target.value
        searchFunction(query)
      }
    }
  }
} // end class

export default Search
