const menuButton = document.getElementsByClassName('c-btn--menu')[0]
const searchButton = document.getElementsByClassName('c-btn--search')[0]
const menu = document.getElementsByClassName('c-navigation')[0]
const topNavItems = document.getElementsByClassName('c-navigation__item')
const subNavItemLinks = document.querySelectorAll('.c-navigation__sub-menu__link')
const main = document.getElementsByClassName('c-pagecontent-main')[0]

class Navigation {
  constructor () {
    this.menuButton = menuButton
    this.searchButton = searchButton
    this.menu = menu
    this.topNavItems = topNavItems
    this.main = main
    this.subNavItemLinks = subNavItemLinks
  }

  bind () {
    this.menuButton.addEventListener('click', (e) => this.toggleMenu(e))
    this.main.addEventListener('click', (e) => this.hideMenuOnBackgroundClick(e))
    for (let i = 0; i < this.topNavItems.length; i++) {
      this.topNavItems[i].addEventListener('click', (e) => this.toggleSubNavMenu(e))
    }
    /* Enable navigation to sub-menu items */
    for (let i = 0; i < this.subNavItemLinks.length; ++i) {
      this.subNavItemLinks[i].addEventListener('click', (e) => (window.location = e.currentTarget.getAttribute('href')))
    }
  }

  toggleMenu () {
    if (this.menuButton.classList.contains('is-open')) {
      this.menuButton.classList.remove('is-open')
      this.searchButton.classList.remove('hide')
      this.menu.classList.remove('is-open')
      this.main.classList.remove('hide')
    } else {
      this.menuButton.classList.add('is-open')
      this.searchButton.classList.add('hide')
      this.menu.classList.add('is-open')
      this.main.classList.add('hide')
    }
  }

  hideMenuOnBackgroundClick () {
    if (this.main.classList.contains('hide') && this.menuButton.classList.contains('is-open')) {
      this.menuButton.classList.remove('is-open')
      this.searchButton.classList.remove('hide')
      this.menu.classList.remove('is-open')
      this.main.classList.remove('hide')
    }
  }

  toggleSubNavMenu (event) {
    const t = event.currentTarget

    if (t.querySelectorAll('.c-navigation__sub-menu').length > 0) {
      event.preventDefault()
    }

    if (t.classList.contains('is-open')) {
      this.closeSubNav(t)
    } else {
      for (let i = 0; i < this.topNavItems.length; i++) {
        const el = this.topNavItems[i]
        if (el.classList.contains('is-open')) {
          this.closeSubNav(el)
        }
      }
      this.openSubNav(t)
    }
  }

  setTabIndexForSubMenus (el, value) {
    const subNavsItems = el.querySelectorAll('.c-navigation__sub-menu__link ')
    for (let j = 0; j < subNavsItems.length; ++j) {
      subNavsItems[j].setAttribute('tabindex', value)
    }
  }

  openSubNav (el) {
    el.querySelector('.js-navigation-arrow').classList.add('is-open')
    el.querySelector('.c-navigation__sub-menu').style.display = ''
    el.classList.add('is-open')
    el.setAttribute('aria-expanded', 'true')
    this.setTabIndexForSubMenus(el, '0')
  }

  closeSubNav (el) {
    el.querySelector('.js-navigation-arrow').classList.remove('is-open')
    el.querySelector('.c-navigation__sub-menu').style.display = 'none'
    el.classList.remove('is-open')
    el.setAttribute('aria-expanded', 'false')
    this.setTabIndexForSubMenus(el, '-1')
  }
} // end class

export default Navigation
