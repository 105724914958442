import Search from './search.js'
import Navigation from './navigation.js'

import '../css/main.css'

const search = new Search()
const navigation = new Navigation()
navigation.bind()

document.addEventListener('DOMContentLoaded', function () {
  search.bind()
  search.init()
})

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration)
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError)
    })
  })
}
